import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import favicon from 'images/favicon.png';

const SEO = ({
	description,
	lang,
	meta,
	keywords,
	title,
	image,
	link,
	noForRobots,
}) => {
	return (
		<Helmet
			htmlAttributes={{
				lang,
			}}
			title={title}
			meta={
				[
					{
						name: `og:title`,
						content: title,
					},
					{
						name: `description`,
						content: description,
					},
					{
						name: `og:description`,
						content: description,
					},
					{
						name: `og:type`,
						content: `website`,
					},
					{
						property: `og:url`,
						content: `https://gianalyzer.com${link}`,
					},
					{
						property: `og:title`,
						content: title,
					},
					{
						property: `og:description`,
						content: description,
					},
					{
						property: `og:type`,
						content: `website`,
					},
					// {
					//   name: `twitter:card`,
					//   content: `summary`,
					// },
					// {
					//   name: `twitter:title`,
					//   content: title,
					// },
					// {
					//   name: `twitter:description`,
					//   content: description,
					// },
				].concat(
					keywords.length > 0
						? {
								name: `keywords`,
								content: keywords.join(`, `),
						  }
						: []
				)
				// .concat(meta)
			}
		>
			{noForRobots && <meta name='robots' content='noindex, nofollow' />}
			<link rel='canonical' href={`https://gianalyzer.com${link}`} />
			<link rel='shortcut icon' type='image/png' href={favicon} />
			<link rel='preconnect' href='https://fonts.gstatic.com' />
			<link
				rel='stylesheet'
				href='https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap'
				as='font'
				crossOrigin
			/>
			<meta
				name="google-site-verification"
				content={process.env.GATSBY_GOOGLE_VERIFICATION}
			/>
			<link
				rel="preconnect"
				href="https://www.googletagmanager.com/"
				crossOrigin
			/>
		</Helmet>
	);
};

SEO.defaultProps = {
	lang: 'en',
	// meta: [],
	keywords: [],
	noForRobots: false,
};

SEO.propTypes = {
	description: PropTypes.string.isRequired,
	lang: PropTypes.string,
	link: PropTypes.string,
	// meta: PropTypes.array,
	keywords: PropTypes.arrayOf(PropTypes.string),
	title: PropTypes.string.isRequired,
	image: PropTypes.string,
	noForRobots: PropTypes.bool,
};

export default SEO;
