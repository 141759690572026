import { useContext, useState, useEffect } from 'react';
import { block } from 'bem-cn';
import loadable from '@loadable/component';

import Container from 'components/Container';
import Link from 'components/Link';
import Image from 'components/Image';
import { DeviceContext, MenuContext } from 'layouts/context';
import { removeActivePageVolume } from 'assets/helpers';

import './style.scss';

const cn = block('main-header');

const HeaderMobileButtonMenu = loadable(() =>
	import('components/Header/headerMobileButtonMenu')
);
const Menu = loadable(() => import('./menu'));

const Header = () => {
	const { isTablet, isMobile } = useContext(DeviceContext);
	const [isOpenMobileMenu, setOpenMobileMenu] = useState(false);
	const [isScrolling, setIsScrolling] = useState(false);

	useEffect(() => {
		const body = document.querySelector('main');
		let prevPosition = 0;

		const detectScroll = () => {
			const nextPosition = body.getBoundingClientRect().top;
			if (nextPosition >= 0) setIsScrolling(false);
			else if (prevPosition < nextPosition) setIsScrolling(false);
			else setIsScrolling(true);
			prevPosition = nextPosition;
		};

		window.addEventListener('scroll', detectScroll);
		return () => window.removeEventListener('scroll', detectScroll);
	}, []);

	useEffect(() => {
		const body = document.querySelector('body');
		body.style.overflow = isOpenMobileMenu && isTablet ? 'hidden' : 'inherit';
		const main = document.getElementsByTagName('main')[0];

		//----multiBrowser support blur effect -----
		isOpenMobileMenu && main.classList.add(cn('body-blur'));
		!isOpenMobileMenu && main.classList.remove(cn('body-blur'));
		//-----------------------------------------=

		!isTablet && setOpenMobileMenu(isTablet);
	}, [isOpenMobileMenu, isTablet]);

	const clearContext = () => {
		removeActivePageVolume();
		setOpenMobileMenu(false);
	};

	return (
		<MenuContext.Provider value={{ isOpenMobileMenu, setOpenMobileMenu }}>
			<header
				id='main-header'
				className={cn('container').mix(isScrolling ? cn('sticky') : '')}
			>
				<Container className={cn('wrap')}>
					<Link href='/' onClick={clearContext} className={cn('logo')}>
						<Image
							src='gianalyzer-logo-large.png'
							alt='Logo of GIAnalyzer Add-in for Microsoft Excel'
						/>
					</Link>
					{isTablet ? (
						<HeaderMobileButtonMenu
							size={isMobile ? 'small' : undefined}
							className={cn('button')}
						/>
					) : (
						<Menu />
					)}
				</Container>
			</header>
		</MenuContext.Provider>
	);
};

export default Header;
