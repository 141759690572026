import PropTypes from "prop-types";
import { block } from "bem-cn";

import "./style.scss";

const cn = block("main-container");

const Container = ({
  children,
  className,
  generalContainerClassName,
  withBackground,
  backgroundContainerClassName,
}) => {
  return (
    <div className={cn("container").mix(generalContainerClassName)}>
      {withBackground ? (
        <div
          className={cn(withBackground ? "backGround" : "").mix(
            backgroundContainerClassName
          )}
        >
          <div className={cn("wrap").mix(className)}>{children}</div>
        </div>
      ) : (
        <div className={cn("wrap").mix(className)}>{children}</div>
      )}
    </div>
  );
};

Container.defaultProps = {
  withBackground: false,
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
  generalContainerClassName: PropTypes.object,
  backgroundContainerClassName: PropTypes.object,
  withBackground: PropTypes.bool,
};

export default Container;
