import { useContext } from 'react';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';

import SEO from 'components/Seo';
import Header from 'components/Header';

import { DeviceContext } from 'layouts/context';

const Footer = loadable(() => import('components/Footer'));

const Layout = ({ children, seo }) => {
	const { isMobile, isTablet } = useContext(DeviceContext);
	const getContext = isMobile !== undefined || isTablet !== undefined;

	return (
		<>
			<SEO {...seo} />
			{getContext && (
				<>
					<Header />
					<main>
						<article>{children}</article>
					</main>
					<Footer />
				</>
			)}
		</>
	);
};

Layout.propTypes = {
	children: PropTypes.node,
};

export default Layout;
