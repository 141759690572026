import { graphql, useStaticQuery } from "gatsby";

import { getImage, GatsbyImage } from "gatsby-plugin-image";
import PropTypes from "prop-types";
import { useMemo } from "react";

const Image = ({ src, alt, className }) => {
  const { allFile } = useStaticQuery(graphql`
    query {
      allFile(filter: { extension: { regex: "/(png|jpg|jpeg)/" } }) {
        nodes {
          relativePath
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED)
          }
        }
      }
    }
  `);

  const match = useMemo(
    () => allFile.nodes.find(({ relativePath }) => `${src}` === relativePath),
    [src]
  );

  const noImage = allFile.nodes.find(
    ({ relativePath }) => `image-not-found.png` === relativePath
  );
  const img = getImage(
    match !== undefined
      ? match.childImageSharp.gatsbyImageData
      : noImage.childImageSharp.gatsbyImageData
  );

  return (
    <GatsbyImage
      image={img}
      loading="lazy"
      alt={alt}
      className={className}
      layout="fixed"
    />
  );
};

Image.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
  className: PropTypes.object,
};

export default Image;
