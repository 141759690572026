import PropTypes from "prop-types";
import { Link as GatsbyLink } from "gatsby";
import { block } from "bem-cn";

import Button_Link_Container from "components/Button/_container";

import "./style.scss";

const cn = block("main-link");

const Link = ({
  children,
  className,
  href,
  color,
  isOutside,
  isButton,
  ...props
}) => {
  return (
    <Button_Link_Container
      el={isOutside ? "a" : GatsbyLink}
      className={
        isButton
          ? className
          : cn("wrap", { color: color, byDefault: !isButton }).mix(className)
      }
      to={isOutside ? null : href}
      href={isOutside ? href : null}
      {...props}
    >
      {children}
    </Button_Link_Container>
  );
};

Link.defaultProps = {
  isButton: false,
  isOutside: false,
  color: "default",
};

Link.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.object,
  href: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    "default",
    "general",
    "primary",
    "secondary",
    "white",
  ]),
  // isButton: PropTypes.bool,
  isOutside: PropTypes.bool,
};

export default Link;
