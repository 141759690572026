export const setActivePageVolume = (pageName) => {
	window.sessionStorage.setItem('active-volume-page', pageName);
};

export const removeActivePageVolume = () => {
	window.sessionStorage.removeItem('active-volume-page');
};

export function elementInViewport(el) {
	var top = el.offsetTop;
	var height = el.offsetHeight;

	while (el.offsetParent) {
		el = el.offsetParent;
		top += el.offsetTop;
	}

	return (
		top + 80 < window.pageYOffset + window.innerHeight &&
		top + height > window.pageYOffset
	);
}
