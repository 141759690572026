import { createElement } from "react";
import PropTypes from "prop-types";
import { block } from "bem-cn";

import "./style.scss";

const cn = block("main-heading");

const Heading = (props) =>
  createElement(
    `h${props.level}`,
    {
      className: cn({
        level: props.level,
        align: props.align,
        color: props.color,
        weight: props.weight,
      }).mix([props.className]),
      id: props.id,
    },
    props.children
  );

Heading.defaultProps = {
  level: 1,
  align: "left",
  weight: "normal",
  color: "default",
  id: "",
};
Heading.propTypes = {
  children: PropTypes.any.isRequired,
  level: PropTypes.number,
  weight: PropTypes.oneOf(["light", "normal", "medium", "bold", "black"]),
  align: PropTypes.oneOf(["left", "center", "right"]),
  color: PropTypes.oneOf([
    "default",
    "general",
    "primary",
    "secondary",
    "white",
  ]),
  className: PropTypes.object,
  id: PropTypes.string,
};

export default Heading;
