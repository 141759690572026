import { createElement } from "react";
import PropTypes from "prop-types";
import { block } from "bem-cn";

import "../style.scss";

const cn = block("main-button");

const Button_Link_Container = ({
  children,
  el,
  className,
  isRed,
  size,
  isDefault,
  ...props
}) =>
  createElement(
    el,
    {
      className: cn("wrap", {
        [size]: true,
        [isRed ? "red" : "white"]: true,
        byDefault: isDefault,
      }).mix(className),
      ...props,
    },
    children
  );

Button_Link_Container.defaultProps = {
  isRed: false,
  size: "medium",
  isDefault: false,
};

Button_Link_Container.propTypes = {
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  el: PropTypes.any.isRequired,
  children: PropTypes.node.isRequired,
  isRed: PropTypes.bool,
  size: PropTypes.oneOf(["large", "medium", "small"]),
  isDefault: PropTypes.bool,
};

export default Button_Link_Container;
